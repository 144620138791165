// extracted by mini-css-extract-plugin
export var btnText = "TwoSidedTeaser-module--btnText--dTUDA";
export var customBTN = "TwoSidedTeaser-module--customBTN--97dVZ";
export var customBorder = "TwoSidedTeaser-module--customBorder--lhyEP";
export var description = "TwoSidedTeaser-module--description--EuOYa";
export var formBTN = "TwoSidedTeaser-module--formBTN--I2usL";
export var listItemText = "TwoSidedTeaser-module--listItemText--6pMYZ";
export var pinkText = "TwoSidedTeaser-module--pinkText--iNoR8";
export var sideLeft = "TwoSidedTeaser-module--sideLeft--xlVGJ";
export var sideListItem = "TwoSidedTeaser-module--sideListItem--RS-Mw";
export var sideRight = "TwoSidedTeaser-module--sideRight--X63dt";
export var sideTitle = "TwoSidedTeaser-module--sideTitle--WyqMc";